#menuWrapper {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 5px 12px;
    color: #fff;
    z-index: 20;
    transition: background-color 200ms linear;
}

.App > div > #menuWrapper.menu-bg,
.App:not(.page-home) > div > #menuWrapper {
    background-color: rgba(0, 0, 0, 0.87);
}

#menuWrapper .fa-search {
    line-height: 120%;
}

.advertisement {
    position: relative
}

.advertisement.above-nav,.advertisement.banner-ad,.advertisement.banner {
    max-width: 100vw;
    background-color: transparent;
    z-index: 0
}

@media (min-width: 768px) {
    .advertisement.above-nav,.advertisement.banner-ad,.advertisement.banner {
        margin-top:20px;
        margin-bottom: 10px
    }
}

.advertisement.above-nav>div:not(.gpt-loaded):not(#div-gpt-ad-72890-0)::before,.advertisement.banner-ad>div:not(.gpt-loaded):not(#div-gpt-ad-72890-0)::before,.advertisement.banner>div:not(.gpt-loaded):not(#div-gpt-ad-72890-0)::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #DFDFDF;
    color: #fff;
    font: 400 14px/1.2em "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
    text-align: center;
    content: 'Advertisement';
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    z-index: -1
}

.advertisement.above-nav>div>div>iframe,.advertisement.banner-ad>div>div>iframe,.advertisement.banner>div>div>iframe {
    background-color: #fff
}

.advertisement.above-nav>div>div,.advertisement.above-nav>div,.advertisement.banner-ad>div>div,.advertisement.banner-ad>div,.advertisement.banner>div>div,.advertisement.banner>div {
    position: relative;
    text-align: center;
    margin: 0 auto
}

.advertisement.above-nav>div,.advertisement.banner-ad>div,.advertisement.banner>div {
    position: relative;
    min-height: 50px;
    background-color: transparent
}

@media (min-width: 768px) {
    .advertisement.above-nav>div,.advertisement.banner-ad>div,.advertisement.banner>div {
        min-height:90px
    }
}

.advertisement.above-nav>div::before,.advertisement.banner-ad>div::before,.advertisement.banner>div::before {
    width: 320px;
    height: 50px
}

@media (min-width: 768px) {
    .advertisement.above-nav>div::before,.advertisement.banner-ad>div::before,.advertisement.banner>div::before {
        width:728px;
        height: 90px
    }
}

@media (max-width: 1000px) {
    .content-block.external.ad {
        background:white !important;
        padding: 0px !important;
        margin: 20px auto !important
    }
}

@media (min-width: 768px) {
    .content-block.external.ad {
        margin:15px auto
    }
}

.content-block.external.ad h4 {
    display: none
}

.content-block.external.ad>div {
    position: relative;
    max-width: 300px;
    z-index: 0;
    min-height: 250px
}

.content-block.external.ad>div:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #DFDFDF;
    color: #fff;
    font: 400 14px/1.2em "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
    text-align: center;
    content: 'Advertisement';
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    z-index: -1;
    width: 100%;
    height: 100%
}

.content-block.external.ad>div>div {
    background-color: #fff
}

@media screen and (max-width: 769px) {
    body.reskin .main-content {
        width:auto
    }
}

#destination_menu {
    position: absolute;
    display: none;
    z-index: 9999;
    background-color: #fafafa;
    font: 400 16px/42px Poppins, Roboto, Helvetica, Arial, sans-serif;
    padding: 8px 24px;
    border: none;
    border-radius: 2px;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.2);
    margin-top: 0px
}

#destination_menu a {
    color: #555
}

#nav-destination-name:hover+#destination_menu,#destination_menu:hover {
    display: block
}

body #nav-secondary #nav-container #nav-destination-name {
    margin-bottom: 0px;
    padding: 16px 16px 18px 16px
}

.grecaptcha-badge {
    display: none !important
}

body.at-expanded-menu-noscroll #main-nav-container {
    z-index: 1000
}

#main-nav-container {
    width: 100%;
    height: 61px;
    box-sizing: border-box;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative;
    z-index: 999
}

.reskin #main-nav-container {
    max-width: 1024px;
    margin: 0 auto;
    z-index: 100001
}

#main-nav-container * {
    box-sizing: border-box
}

@media (max-width: 768px) {
    #main-nav-container {
        height:50px
    }
}

#main-nav {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    /* background: #fff;
    border-bottom: 1px solid #f7f7f7; */
    position: relative;
    z-index: 10000000
}

#main-nav .main-nav-wrapper {
    width: auto;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 30px
}

@media (max-width: 1279px) {
    #main-nav .main-nav-wrapper {
        padding:0 20px
    }
}

#main-nav .main-nav-body {
    height: 60px
}

@media (max-width: 768px) {
    #main-nav .main-nav-body {
        height:50px
    }
}

#main-nav .main-nav-home {
    padding: 0;
    float: left
}

#main-nav .main-nav-home a {
    height: 25px;
    display: block
}

@media (max-width: 768px) {
    #main-nav .main-nav-home {
    }

    #main-nav .main-nav-home a {
        height: 20px;
    }
}

#main-nav .main-nav-home .home-wrap {
    margin: 0px
}

#main-nav .main-nav-home .home-tip h1 {
    font: inherit;
    margin: 0
}

#main-nav .main-nav-nav {
    float: right
}

#main-nav .main-nav-nav a {
    width: auto;
    padding: 21px 20px;
    color: #323232;
    font: 14px/18px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    display: block;
    float: left;
    position: relative
}

#main-nav .main-nav-nav a.current,#main-nav .main-nav-nav a.hover {
    color: #f07d31
}

@media (max-width: 1279px) {
    #main-nav .main-nav-nav a {
        padding:21px 10px
    }
}

#main-nav .main-nav-nav>a:before {
    width: auto;
    height: 3px;
    left: 20px;
    right: 20px;
    bottom: 0;
    content: "";
    background: #fff;
    position: absolute
}

@media (max-width: 1279px) {
    #main-nav .main-nav-nav>a:before {
        left:10px;
        right: 10px
    }
}

#main-nav .main-nav-nav>a.hover {
    color: #f07d31
}

#main-nav .main-nav-nav>a.hover:before {
    background: #f07d31
}

@media (min-width: 769px) and (max-width: 1023px) {
    #main-nav .main-nav-nav>a.nav-item[data-id="trip-ideas"] {
        display:none
    }
}

@media (min-width: 769px) and (max-width: 991px) {
    #main-nav .main-nav-nav>a.nav-item[data-id="forums"] {
        display:none
    }
}

#main-nav .main-nav-nav .main-nav-subscribe {
    float: left;
    display: none
}

#main-nav .main-nav-nav .main-nav-subscribe a {
    color: #8e8e8e;
    display: block
}

#main-nav .main-nav-nav .main-nav-subscribe a:hover {
    color: #f07d31
}

#main-nav .main-nav-nav .main-nav-subscribe a .fa-envelope-o {
    margin-right: 4px
}

#main-nav .main-nav-nav .main-nav-subscribe.toggle a {
    color: #f07d31
}

@media (min-width: 1380px) {
    #main-nav .main-nav-nav .main-nav-subscribe {
        display:block
    }
}

#main-nav .main-nav-nav .main-nav-manage-subscriptions {
    float: left;
    display: none
}

#main-nav .main-nav-nav .main-nav-manage-subscriptions a {
    color: #8e8e8e;
    display: block
}

#main-nav .main-nav-nav .main-nav-manage-subscriptions a:hover {
    color: #f07d31
}

#main-nav .main-nav-nav .main-nav-manage-subscriptions a .fa-envelope-o {
    margin-right: 4px
}

@media (min-width: 1380px) {
    #main-nav .main-nav-nav .main-nav-manage-subscriptions {
        display:block
    }
}

#main-nav .main-nav-nav.search {
    display: none
}

#main-nav .main-nav-login {
    float: left
}

@media (min-width: 769px) and (max-width: 1023px) {
    #main-nav .main-nav-login {
        display:none
    }
}

#main-nav .main-nav-login a {
    color: #8e8e8e;
    font: 14px/18px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-transform: uppercase;
    text-decoration: none;
    padding-right: 0;
    display: block
}

#main-nav .main-nav-login a:hover {
    color: #f07d31
}

#main-nav .main-nav-login a .fa-user-circle-o {
    margin-right: 4px
}

#main-nav .main-nav-login.toggle a {
    color: #f07d31
}

#main-nav .main-nav-overflow {
    display: none;
    float: left
}

#main-nav .main-nav-overflow .main-nav-overflow-toggle {
    padding-right: 0
}

#main-nav .main-nav-overflow .main-nav-overflow-toggle .fa {
    color: #f07d31
}

#main-nav .main-nav-overflow .main-nav-overflow-dropdown {
    position: absolute;
    top: 100%;
    display: none;
    background-color: #fff
}

#main-nav .main-nav-overflow .main-nav-overflow-dropdown.overflow-expanded {
    display: block
}

#main-nav .main-nav-overflow .main-nav-overflow-dropdown .main-nav-overflow-item>a {
    float: none;
    padding: 15px 14px 14px;
    border-bottom: 1px solid #8e8e8e
}

@media (min-width: 769px) and (max-width: 1023px) {
    #main-nav .main-nav-overflow {
        display:block
    }
}

@media (min-width: 992px) and (max-width: 1023px) {
    #main-nav .main-nav-overflow .main-nav-overflow-dropdown .overflow-item-forums {
        display:none
    }
}

#main-nav .main-nav-mobile-toggle {
    float: left;
    display: none;
    position: relative;
    padding: 15px 0
}

.main-nav-link-logo {
    padding: 6px;
    display: block;
}

#main-nav .main-nav-mobile-toggle .fa {
    float: left;
    width: 20px;
    font-size: 20px;
    line-height: 20px;
    color: #8e8e8e;
    text-align: center
}

#main-nav .main-nav-mobile-toggle.toggle a {
    background-position: left bottom
}

@media screen and (max-width: 1000px) {
    #main-nav .main-nav-mobile-toggle {
        display:block;
        margin-right: calc((100% - 305px) / 2)
    }
}

#main-nav .main-nav-mobile-search {
    float: right;
    display: none;
    padding: 20px 0;
    margin-left: 20px;
    position: relative
}

#main-nav .main-nav-mobile-search .fa {
    float: left;
    width: 20px;
    font-size: 20px;
    line-height: 20px;
    color: #8e8e8e;
    text-align: center
}

#main-nav .main-nav-mobile-search.close-search {
    display: block
}

@media (min-width: 768px) and (max-width: 1000px) {
    #main-nav .main-nav-mobile-search {
        display:block
    }

    .minimal-nav #main-nav .main-nav-mobile-search {
        display: none
    }
}

@media screen and (max-width: 1000px) {
    #main-nav .main-nav-mobile-search {
        display:block;
        float: left;
        padding: 15px 0;
        margin-left: calc((100% - 305px) / 2)
    }
}

@media (min-width: 1280px) {
    #body-wrap.ad-active #main-nav .main-nav-mobile-search {
        display:block
    }
}

#main-nav .main-nav-search {
    width: 260px;
    margin-left: 60px;
    padding: 12px 0 0;
    float: left
}

@media (max-width: 1279px) {
    #main-nav .main-nav-search {
        width:184px;
        margin-left: 30px
    }
}

@media (max-width: 1024px) {
    #main-nav .main-nav-search {
        display:none
    }
}

@media (min-width: 1280px) {
    #body-wrap.ad-active #main-nav .main-nav-search {
        display:none
    }
}

#main-nav .main-nav-search form {
    padding: 0;
    position: relative
}

#main-nav .main-nav-search .fa-search {
    float: left;
    width: 20px;
    font-size: 20px;
    line-height: 20px;
    color: #8e8e8e;
    margin-right: 8px;
    padding: 8px 0;
    text-align: center
}

#main-nav .main-nav-search input[type=text] {
    width: calc(100% - 28px);
    padding: 9px 0;
    outline: 0;
    display: block;
    float: left;
    border-color: white;
    border-style: none;
    font: 14px/18px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    overflow: hidden;
    text-overflow: ellipsis
}

#main-nav .main-nav-search input[type=text]::-webkit-input-placeholder {
    color: #a7a7a7
}

#main-nav .main-nav-search input[type=text]::-moz-placeholder {
    color: #a7a7a7
}

#main-nav .main-nav-search input[type=text]:-ms-input-placeholder {
    color: #a7a7a7
}

#main-nav .main-nav-search input[type=text]:-moz-placeholder {
    color: #a7a7a7
}

#main-nav .main-nav-search button[type=submit] {
    background-color: #f07d31;
    color: #fff;
    font: 14px/13px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    padding: 11px 15px;
    border-radius: 15px;
    text-transform: uppercase;
    display: none
}

#main-nav .main-nav-search button[type=submit] .fa-rotate-315 {
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    transform: rotate(315deg)
}

#main-nav .main-nav-search.focus {
    width: calc(100% - 230px);
    display: block
}

@media (min-width: 1280px) {
    #body-wrap.ad-active #main-nav .main-nav-search.focus {
        display:block
    }
}

#main-nav .main-nav-search.focus input[type=text] {
    width: calc(100% - 108px)
}

#main-nav .main-nav-search.focus button[type=submit] {
    display: block
}

@media (min-width: 1280px) {
    #main-nav .main-nav-search.focus {
        width:calc(100% - 260px)
    }
}

@media (max-width: 768px) {
    #main-nav .main-nav-search.focus {
        width:100%;
        margin-left: 0
    }
}

#main-nav-flyout-mobile-search {
    display: none;
    height: 50px;
    overflow: hidden;
}

#main-nav-flyout-mobile-search .main-nav-search {
    width: calc(100% - 40px);
    margin-left: 20px;
    padding: 7px 0;
    float: left
}

#main-nav-flyout-mobile-search .main-nav-search form {
    padding: 0;
    position: relative
}

#main-nav-flyout-mobile-search .main-nav-search .fa-search {
    float: left;
    width: 20px;
    font-size: 20px;
    line-height: 20px;
    color: #8e8e8e;
    margin-right: 8px;
    padding: 8px 0;
    text-align: center
}

#main-nav-flyout-mobile-search .main-nav-search input[type=text] {
    width: calc(100% - 128px);
    padding: 9px 0;
    outline: 0;
    display: block;
    float: left;
    border-color: transparent;
    background-color: transparent;
    color: #fff;
    border-style: none;
    font: 14px/18px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    overflow: hidden;
    text-overflow: ellipsis
}

#main-nav-flyout-mobile-search .main-nav-search input[type=text]::-webkit-input-placeholder {
    color: #ddd
}

#main-nav-flyout-mobile-search .main-nav-search input[type=text]::-moz-placeholder {
    color: #ddd
}

#main-nav-flyout-mobile-search .main-nav-search input[type=text]:-ms-input-placeholder {
    color: #ddd
}

#main-nav-flyout-mobile-search .main-nav-search input[type=text]:-moz-placeholder {
    color: #ddd
}

#main-nav-flyout-mobile-search .main-nav-search button[type=submit] {
    background-color: #f07d31;
    color: #fff;
    font: 14px/13px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    padding: 11px 15px;
    border-radius: 15px;
    text-transform: uppercase;
    float: right
}

#main-nav-flyout-mobile-search .main-nav-search button[type=submit] .fa-rotate-315 {
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    transform: rotate(315deg)
}

#main-nav-flyout-mobile-search.focus {
    display: block;
    width: 100%;
}

@media screen and (min-width: 769px) {
    #main-nav-flyout .main-nav-flyout-search input[type=text] {
        font-size:14px
    }
}

#main-nav-flyout {
    width: 100%;
    height: auto;
    max-height: 0;
    margin-top: -1px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative;
    z-index: 1000000000;
    display: block;
    overflow: hidden;
    position: fixed;
    top: 55px;
    margin-left: -12px;
}

#main-nav-flyout>div {
    display: none
}

#main-nav-flyout.show {
    max-height: 380px;
    overflow: hidden;
}
#main-nav-flyout>div.show {
    display: block;
}

#main-nav-flyout .main-nav-flyout-close {
    width: 16px;
    height: 16px;
    right: 20px;
    top: 20px;
    background: transparent url(../../assets/icons/main-nav-flyout-close.svg) no-repeat center;
    background-size: 16px;
    display: block;
    position: absolute;
    cursor: pointer;
    z-index: 2
}

#main-nav-flyout .main-nav-flyout-wrapper {
    width: 940px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    z-index: 1
}

#main-nav-flyout .main-nav-flyout-destinations {
    width: inherit;
    position: relative
}

#main-nav-flyout .main-nav-flyout-destinations:before,#main-nav-flyout .main-nav-flyout-destinations:after {
    width: 50%;
    top: 0;
    bottom: 0;
    content: "";
    position: absolute
}

#main-nav-flyout .main-nav-flyout-destinations:before {
    left: 0;
    background: #254a6d
}

#main-nav-flyout .main-nav-flyout-destinations:after {
    right: 0;
    background: #dfdfdf
}
.main-nav-flyout-destinations-sidebar {
    display:flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}
#main-nav-flyout .main-nav-flyout-destinations-sidebar {
    position: absolute;
    width: 210px;
    float: left
}

#main-nav-flyout .main-nav-flyout-destinations-sidebar a {
    color: #fff;
    font: 14px/40px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-decoration: none;
    display: block;
    position: relative
}

#main-nav-flyout .main-nav-flyout-destinations-sidebar a:before {
    width: 1000%;
    left: -1000%;
    top: 0;
    bottom: 0;
    content: "";
    background: #3c5d7c;
    position: absolute;
    display: none
}

.reskin #main-nav-flyout .main-nav-flyout-destinations-sidebar a:before {
    width: 42px;
    left: -42px
}

#main-nav-flyout .main-nav-flyout-destinations-sidebar a:after {
    width: 5px;
    height: 9px;
    right: 16px;
    top: 16px;
    content: "";
    background: transparent url(../../assets/icons/main-nav-flyout-arrow.svg) no-repeat center top;
    background-size: 5px;
    position: absolute;
    display: none
}

#main-nav-flyout .main-nav-flyout-destinations-sidebar a:hover,#main-nav-flyout .main-nav-flyout-destinations-sidebar a.hover {
    background: #3c5d7c
}

#main-nav-flyout .main-nav-flyout-destinations-sidebar a:hover:before,#main-nav-flyout .main-nav-flyout-destinations-sidebar a:hover:after,#main-nav-flyout .main-nav-flyout-destinations-sidebar a.hover:before,#main-nav-flyout .main-nav-flyout-destinations-sidebar a.hover:after {
    display: block
}

#main-nav-flyout .main-nav-flyout-destinations-main {
    box-sizing: border-box;
    width: auto;
    margin-left: 210px;
    padding: 38px 0 0 31px;
    background: #dfdfdf;
    position: relative;
    padding-bottom:20px;
}

#main-nav-flyout .main-nav-flyout-destinations-main>div {
    display: none
}

#main-nav-flyout .main-nav-flyout-destinations-main>div.show {
    display: block
}

#main-nav-flyout .main-nav-flyout-destinations-main p {
    margin: 0;
    padding: 0 0 18px;
    color: #323232;
    font: 20px/22px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-transform: uppercase;
    display: block
}

#main-nav-flyout .main-nav-flyout-destinations-top {
    margin: 0 0 19px;
    padding: 0 0 40px;
    border-bottom: 1px solid #a7a7a7;
    height: 234px;
}

#main-nav-flyout .main-nav-flyout-destinations-top-primary {
    height: 160px;
    margin: 0 30px 0 0;
    float: left
}

#main-nav-flyout .main-nav-flyout-destinations-top-primary a {
    width: 132px;
    height: 28px;
    margin: 0 0 0 20px;
    padding: 130px 13px 0;
    color: #fff;
    font: 16px/18px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-decoration: none;
    border: 1px solid #cdcdcd;
    background: transparent none no-repeat center;
    background-size: auto 200px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    display: block;
    float: left;
    position: relative
}

#main-nav-flyout .main-nav-flyout-destinations-top-primary a span {
    right: 13px;
    left: 13px;
    bottom: 13px;
    position: absolute;
    z-index: 1
}

#main-nav-flyout .main-nav-flyout-destinations-top-primary a:first-child {
    margin: 0
}

#main-nav-flyout .main-nav-flyout-destinations-top-primary a:hover {
    background-size: auto 225px
}

#main-nav-flyout .main-nav-flyout-destinations-top-primary a:after {
    width: auto;
    height: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: transparent url(../../assets/icons/main-nav-flyout-destinations.png) no-repeat center;
    background-size: cover;
    position: absolute;
    z-index: 0
}

#main-nav-flyout .main-nav-flyout-destinations-top-secondary {
    min-height: 160px;
    margin: 0 0 0 480px
}

#main-nav-flyout .main-nav-flyout-destinations-top-secondary a, #main-nav-flyout .main-nav-flyout-destinations-top-secondary > span {
    padding: 0 0 7px;
    color: #323232;
    font: 15px/18px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    display: block
}

#main-nav-flyout .main-nav-flyout-destinations-top-secondary a.main-nav-flyout-destinations-top-secondary-all {
    margin: 11px 0 0;
    padding: 17px 0 0;
    border-top: 1px dotted #323232;
    font: 12px/14px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-transform: uppercase
}

#main-nav-flyout .main-nav-flyout-destinations-top-secondary a.main-nav-flyout-destinations-top-secondary-all:after {
    width: 5px;
    height: 9px;
    margin: 0 0 0 6px;
    content: "";
    background: transparent url(../../assets/icons/main-nav-flyout-arrow.svg) no-repeat center bottom;
    background-size: 5px;
    display: inline-block
}

#main-nav-flyout .main-nav-flyout-destinations-all {
    color: #323232;
    font: 12px/14px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-transform: uppercase
}

#main-nav-flyout .main-nav-flyout-destinations-all:after {
    width: 5px;
    height: 9px;
    margin: 0 0 0 6px;
    content: "";
    background: transparent url(../../assets/icons/main-nav-flyout-arrow.svg) no-repeat center bottom;
    background-size: 5px;
    display: inline-block
}

#main-nav-mobile {
    box-sizing: border-box;
    width: 240px;
    padding: 20px 0;
    left: -240px;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    background: #323232;
    position: fixed;
    display: block;
    z-index: 0;
    overflow-y: scroll
}

#main-nav-mobile .main-nav-mobile-links {
    margin-bottom: 25px
}

#main-nav-mobile .main-nav-mobile-links>a,#main-nav-mobile .main-nav-mobile-links .main-nav-mobile-collapsable>a,
#main-nav-mobile .main-nav-mobile-links>button,#main-nav-mobile .main-nav-mobile-links .main-nav-mobile-collapsable>button {
    width: auto;
    padding: 0 20px;
    height: 36px;
    color: #fff;
    font: 16px/36px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    position: relative;
    z-index: 1
}

.main-nav-mobile-link {
    background: none;
    padding: 0;
    border: 0;
}

#main-nav-mobile .main-nav-mobile-links>a:focus,#main-nav-mobile .main-nav-mobile-links .main-nav-mobile-collapsable>a:focus {
    background-color: #474747
}

#main-nav-mobile .main-nav-mobile-links .main-nav-mobile-collapsable {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden
}

#main-nav-mobile .main-nav-mobile-links .main-nav-mobile-collapsable>a .fa,
#main-nav-mobile .main-nav-mobile-links .main-nav-mobile-collapsable>button .fa {
    position: absolute;
    right: 20px;
    top: 7px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    line-height: 16px;
    text-align: center
}
#main-nav-mobile .main-nav-mobile-links .main-nav-mobile-collapsable>button .fa {
    right: 0;
}

#main-nav-mobile .main-nav-mobile-links .main-nav-mobile-collapsable .main-nav-mobile-collapsable-children {
    display: none
}

#main-nav-mobile .main-nav-mobile-links .main-nav-mobile-collapsable .main-nav-mobile-collapsable-children.show {
    display: block
}

#main-nav-mobile .main-nav-mobile-links .main-nav-mobile-collapsable .main-nav-mobile-collapsable-children a {
    width: auto;
    padding: 0 20px;
    height: 24px;
    color: #fff;
    font: 14px/30px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-decoration: none;
    text-indent: 10px;
    display: block;
    white-space: nowrap;
}

#main-nav-mobile hr {
    width: calc(100% - 40px);
    margin: 0 auto;
    border: 0;
    border-top: 1px solid #cdcdcd
}

#main-nav-mobile .main-nav-mobile-login {
    margin: 10px 0
}

#main-nav-mobile .main-nav-mobile-login a {
    width: 100%;
    padding: 0 20px;
    color: #fff;
    font: 14px/16px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    display: block
}

#main-nav-mobile .main-nav-mobile-login a .fa {
    margin-right: 8px
}

#main-nav-mobile .main-nav-mobile-subscribe {
    margin: 15px 0 10px
}

#main-nav-mobile .main-nav-mobile-subscribe a {
    width: 100%;
    padding: 0 20px;
    color: #fff;
    font: 14px/16px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    display: block
}

#main-nav-mobile .main-nav-mobile-subscribe a .fa {
    margin-right: 8px
}

#main-nav-mobile-close {
    top: 0;
    right: 0;
    bottom: 0;
    left: -40px;
    display: none;
    position: fixed;
    cursor: pointer;
    z-index: 1000000000;
    color: white;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out
}

#main-nav-mobile-close .fa-times {
    display: none;
    color: white;
    font-size: 16px;
    margin-left: 16px;
    margin-top: -25px
}

@media (min-width: 768px) {
    #main-nav-mobile-close .fa-times {
        margin-top:-26px
    }
}

#main-nav-mobile-close .dot {
    display: none;
    color: #323232;
    height: 24px;
    width: 24px;
    background-color: #323232;
    border-radius: 50%;
    margin-left: 10px;
    margin-top: 7px
}

@media screen and (max-width: 1000px) {
    body {
        left:0;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        position: relative
    }

    body #main-nav {
        z-index: 999
    }

    body #main-nav-mobile {
        z-index: 100000000000001
    }

    body.main-nav-mobile {
        overflow-y: hidden
    }

    body.main-nav-mobile #main-nav-mobile {
        left: 0;
        z-index: 100000000000001
    }

    body.main-nav-mobile #main-nav-mobile-close {
        display: block
    }

    body.main-nav-mobile #main-nav-mobile-close.slide-in {
        left: 240px
    }

    body.main-nav-mobile #main-nav-mobile-close.slide-in i {
        display: block
    }

    body.main-nav-mobile #main-nav-mobile-close.slide-in .dot {
        display: inline-block
    }

    body.main-nav-mobile #main-nav {
        position: relative;
        z-index: 10000000000000
    }

    body.main-nav-mobile-fixed {
        position: fixed !important
    }

    body.main-nav-mobile-home {
        position: relative !important
    }

    body.reskin #checkm8Cont_0 {
        width: auto !important
    }

    body.reskin #checkm8Cont_1,body.reskin #checkm8Cont_2,body.reskin #checkm8Cont_3,body.reskin #checkm8Cont_4 {
        display: none !important
    }

    #main-nav-container {
        height: auto;
        padding-bottom: 0 !important
    }

    #main-nav .main-nav-wrapper {
        width: auto
    }

    #main-nav .main-nav-meta {
        display: none
    }

    #main-nav .main-nav-nav {
        display: none
    }

    #main-nav .main-nav-search {
        display: none;
        position: relative
    }

    #main-nav .main-nav-search a {
        width: auto;
        height: 15px;
        padding: 0 24px 0 0;
        right: 0;
        color: #555;
        font: 14px/15px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
        text-decoration: none;
        text-indent: 0;
        text-transform: uppercase;
        display: inline-block;
        position: absolute
    }

    #main-nav .main-nav-search:hover a,#main-nav .main-nav-search.toggle a {
        color: #f07d31
    }

    #main-nav-flyout .main-nav-flyout-wrapper {
        width: auto
    }

    #main-nav-flyout .main-nav-flyout-search input[type=text] {
        width: 100%;
        height: 38px;
        margin: 0 0 15px;
        font-size: 16px;
        float: none;
        box-sizing: border-box
    }

    #main-nav-flyout .main-nav-flyout-search input[type=submit] {
        margin-left: -40px;
        left: 50%;
        position: relative
    }

    #main-nav-flyout .main-nav-flyout-search-searches {
        text-align: center
    }
}

@media screen and (max-width: 1000px) {
    #main-nav-flyout .main-nav-flyout-search form {
        float:none !important
    }
}

#footer-lock-up *,#footer-lock-up *:before,#footer-lock-up *:after,#main-footer *,#main-footer *:before,#main-footer *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

#footer-lock-up .container,#main-footer .container {
    margin-right: auto;
    margin-left: auto
}

#footer-lock-up .container:before,#footer-lock-up .container:after,#main-footer .container:before,#main-footer .container:after {
    content: " ";
    display: table
}

#footer-lock-up .container:after,#main-footer .container:after {
    clear: both
}

@media (min-width: 768px) {
    #footer-lock-up .container,#main-footer .container {
        width: 740px
    }
}

@media (min-width: 992px) {
    #footer-lock-up .container,#main-footer .container {
        width: 960px
    }
}

@media (min-width: 1200px) {
    #footer-lock-up .container,#main-footer .container {
        width: 1160px
    }
}

#footer-lock-up .container .row,#main-footer .container .row {
    margin: 0 auto
}

#footer-lock-up .container .row:before,#footer-lock-up .container .row:after,#main-footer .container .row:before,#main-footer .container .row:after {
    content: " ";
    display: table
}

#footer-lock-up .container .row:after,#main-footer .container .row:after {
    clear: both
}

#footer-lock-up .hide,#main-footer .hide {
    display: none !important
}

#footer-lock-up .show,#main-footer .show {
    display: block !important
}

#footer-lock-up .col-xs-*,#footer-lock-up .col-sm-*,#footer-lock-up .col-md-*,#main-footer .col-xs-*,#main-footer .col-sm-*,#main-footer .col-md-* {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}
#main-nav-flyout .main-nav-flyout-wrapper{
    width: 100%;
    margin-left: 30%;
}
#main-nav-flyout .main-nav-flyout-destinations-sidebar a:before {
    background-color: rgba(15, 15, 15, 0.70);
}
#main-nav-flyout .main-nav-flyout-destinations-sidebar a:hover {
    background-color: rgba(15, 15, 15, 0.70);
}
#main-nav-flyout .main-nav-flyout-destinations:before{
    background: rgba(0, 0, 0, 0.70);
}
#main-nav-flyout .main-nav-flyout-destinations:after {
    background: rgba(0, 0, 0, 0.70);
}
#main-nav-flyout .main-nav-flyout-destinations-main {
    background: rgba(255,255,255,0.90);
}
#main-nav-flyout .main-nav-flyout-destinations-top-primary a{
    border-color: rgba(0, 0, 0, 0.5);
}
#main-nav-flyout .main-nav-flyout-destinations-top-secondary a.main-nav-flyout-destinations-top-secondary-all{
    border-top-style:solid;
    border-top-width: 1px;
    border-top-color:rgba(167, 167, 167);
}
#main-nav-flyout .main-nav-flyout-destinations-sidebar a.hover {
    background: rgba(15, 15, 15, 0.8);
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgba(15, 15, 15, 0.70);
}
::selection {
    background-color: rgba(27, 27, 27, 0.87);
}
#footer-lock-up .col-xs-6,#main-footer .col-xs-6 {
    width: 50%
}

@media (min-width: 768px) {
    #footer-lock-up .col-sm-4,#main-footer .col-sm-4 {
        width: 33.33333333%
    }
}

@media (max-width: 1000px) {
    #footer-lock-up .hidden-xs,#main-footer .hidden-xs {
        display: none !important
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #footer-lock-up .hidden-sm,#main-footer .hidden-sm {
        display: none !important
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    #footer-lock-up .hidden-md,#main-footer .hidden-md {
        display: none !important
    }
}

@media (min-width: 1200px) {
    #footer-lock-up .hidden-lg,#main-footer .hidden-lg {
        display: none !important
    }
}

#main-footer {
    width: 100%;
    margin: 20px auto 0;
    padding: 20px 0 0;
    background: #323232;
    position: relative;
    clear: both
}

@media (min-width: 768px) {
    #main-footer {
        margin: 0 auto;
        padding: 50px 0 0
    }
}

.reskin #main-footer {
    max-width: 984px
}

#main-footer .main-footer-wrapper {
    border-bottom: 1px solid #898989;
    padding: 10px 20px 20px
}

@media (min-width: 768px) {
    #main-footer .main-footer-wrapper {
        padding: 10px 0 20px
    }
}

#main-footer .main-footer-wrapper ul {
    float: left;
    padding-left: 0
}

#main-footer .main-footer-wrapper ul li {
    padding: 0 0 20px;
    color: #fff;
    font: 14px/1.7em "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    text-decoration: none;
    list-style: none
}

#main-footer .main-footer-wrapper ul li a {
    color: #fff;
    font: 14px/1.7em "Poppins", "Roboto", Helvetica, Arial, Sans-Serif
}

#main-footer .main-footer-wrapper .mnav-right,#main-footer .main-footer-wrapper .mnav-left {
    clear: both;
    position: relative;
    min-height: 1px
}

@media (min-width: 768px) {
    #main-footer .main-footer-wrapper .mnav-right,#main-footer .main-footer-wrapper .mnav-left {
        clear: none
    }
}

@media (min-width: 768px) {
    #main-footer .main-footer-wrapper .mnav-right {
        float: right;
        width: 45%
    }
}

@media (min-width: 992px) {
    #main-footer .main-footer-wrapper .mnav-right {
        width: 33%
    }
}

@media (min-width: 768px) {
    #main-footer .main-footer-wrapper .mnav-left {
        float: left;
        width: 55%
    }
}

@media (min-width: 992px) {
    #main-footer .main-footer-wrapper .mnav-left {
        width: 66%
    }
}

#main-footer .main-footer-social {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 10px;
    line-height: 14px
}

@media (min-width: 768px) {
    #main-footer .main-footer-social {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 0
    }
}

#main-footer .main-footer-social h4 {
    color: #fff;
    font: 14px/1.7em "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    padding-right: 20px;
    padding-bottom: 10px;
    clear: both;
    display: block;
    text-align: center;
    text-transform: none;
    margin: 0
}

@media (min-width: 768px) {
    #main-footer .main-footer-social h4 {
        clear: none;
        display: inline-block;
        text-align: left
    }
}

#main-footer .main-footer-social img {
    vertical-align: middle;
    margin-right: 15px
}

@media (min-width: 768px) {
    #main-footer .main-footer-social img {
        margin-right: 10px
    }
}

@media (min-width: 992px) {
    #main-footer .main-footer-social img {
        margin-right:15px
    }
}

#main-footer .main-footer-logo {
    background-size: auto;
    background-repeat: no-repeat;
}

#main-footer .newsletter-signup {
    color: #fff;
    font: 14px/35px "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    border: 1px solid #fff;
    width: 100%;
    text-align: center;
    border-radius: 4px
}

#main-footer .newsletter-signup .icon {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 5px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: 0 8px;
    background-image: url("../../assets/icons/mnav/newsletter.svg")
}

#main-footer .main-footer-subscribe {
    display: none;
    position: fixed;
    top: 1500px;
    left: 0;
    bottom: -400px;
    transition: all 1s ease-in-out;
    width: 100%;
    background: #091f33;
    padding: 40px 20px
}

@media (min-width: 768px) {
    #main-footer .main-footer-subscribe {
        display: block;
        position: relative;
        background: transparent;
        top: auto;
        left: auto;
        bottom: auto;
        top: initial;
        left: initial;
        bottom: initial;
        padding: 0
    }
    #main-footer .main-footer-subscribe .jsDesktopToggle {
        display: none
    }
    #main-footer .main-footer-subscribe .jsDesktopToggle.jsShow {
        display: block
    }
}

#main-footer .main-footer-subscribe.jsSlideUp {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 380px;
    z-index: 1000001
}

#main-footer .main-footer-subscribe fieldset.submit {
    margin-top: 20px
}

#main-footer .main-footer-subscribe input[type="text"] {
    background: #091f33;
    color: #fff;
    margin-bottom: 10px;
    border: 1px solid #fff;
    width: 100%;
    padding: 5px 10px;
    height: 40px;
    border-radius: 0;
    font: 16px/1.7em "Poppins", "Roboto", Helvetica, Arial, Sans-Serif
}

@media (min-width: 768px) {
    #main-footer .main-footer-subscribe input[type="text"] {
        font: 14px/1.7em "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
        height: auto;
        background: #323232;
        width: 220px
    }
}

@media (min-width: 992px) {
    #main-footer .main-footer-subscribe input[type="text"] {
        width: 240px
    }
}

#main-footer .main-footer-subscribe input[type="checkbox"] {
    margin-left: 0
}

#main-footer .main-footer-subscribe input[type="submit"] {
    height: auto;
    background-color: #f07d31;
    color: #fff;
    font: 14px/1.7em "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    width: 100%;
    padding: 8px
}

#main-footer .main-footer-subscribe input[type="submit"].focus,#main-footer .main-footer-subscribe input[type="submit"]:focus,#main-footer .main-footer-subscribe input[type="submit"]:active:focus {
    outline: 5px auto #f07d31
}

@media (min-width: 768px) {
    #main-footer .main-footer-subscribe input[type="submit"] {
        width: auto;
        padding: 8px 12px
    }
}

#main-footer .main-footer-subscribe label {
    color: #a7a7a7;
    font: 400 12px/20px "Roboto", "Poppins"
}

#main-footer .main-footer-subscribe label strong {
    color: #fff
}

#main-footer .main-footer-subscribe .btn {
    margin: 0;
    top: 0;
    border-radius: 4px;
    border: 0
}

#main-footer .main-footer-subscribe .close {
    opacity: 1
}

#main-footer .main-footer-subscribe .newsletter-close {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 10px;
    right: 10px
}

#main-footer .main-footer-subscribe .footer-subscribe-error {
    display: none;
    color: #f07d31;
    transition: all 1s ease-in-out;
    font: 13px/15px "Roboto", Helvetica, Arial, Sans-Serif;
    height: 25px
}

#main-footer .main-footer-subscribe .footer-subscribe-success {
    display: none;
    color: #fff;
    font: 400 16px/1.5em "Roboto", Helvetica, Arial, Sans-Serif
}

#main-footer .main-footer-subscribe .footer-subscribe-success strong {
    display: block
}

@media (min-width: 768px) {
    #main-footer .main-footer-subscribe .footer-subscribe-success strong {
        display: inline-block
    }
}

#main-footer .main-footer-subscribe .footer-subscribe-success .br2 {
    display: inline-block
}

@media (min-width: 768px) {
    #main-footer .main-footer-subscribe .footer-subscribe-success .br2 {
        display: block
    }
}

#main-footer .main-footer-subscribe form {
    width: 100%;
    margin: 0 auto;
    max-width: 400px;
    padding-left: 10px;
    padding-right: 10px
}

@media (min-width: 768px) {
    #main-footer .main-footer-subscribe form {
        width: auto;
        max-width: none;
        padding: 0
    }
}

#main-footer .main-footer-subscribe .footer-subscribe-error,#main-footer .main-footer-subscribe .footer-subscribe-success,#main-footer .main-footer-subscribe .stay-updated {
    width: 100%;
    margin: 0 auto 10px;
    max-width: 400px
}

@media (min-width: 768px) {
    #main-footer .main-footer-subscribe .footer-subscribe-error,#main-footer .main-footer-subscribe .footer-subscribe-success,#main-footer .main-footer-subscribe .stay-updated {
        width:auto;
        max-width: none
    }
}

#main-footer .main-footer-subscribe .footer-subscribe-error,#main-footer .main-footer-subscribe .footer-subscribe-success {
    padding-left: 10px
}

@media (min-width: 768px) {
    #main-footer .main-footer-subscribe .footer-subscribe-error,#main-footer .main-footer-subscribe .footer-subscribe-success {
        padding-left:0
    }
}

#main-footer .main-footer-subscribe .stay-updated {
    color: #fff;
    font: 16px/1.7em "Poppins", "Roboto", Helvetica, Arial, Sans-Serif;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    padding-left: 40px;
    background-position: 10px 5px;
    background-image: url("../../assets/icons/mnav/newsletter.svg")
}

@media (min-width: 768px) {
    #main-footer .main-footer-subscribe .stay-updated {
        padding-left: 30px;
        background-position: 0 2px
    }
}

#footer-lock-up {
    width: 100%;
    margin: 0 auto;
    background: #323232;
    color: #fff;
    text-align: center
}

@media (min-width: 768px) {
    #footer-lock-up {
        text-align: left
    }
}

#footer-lock-up .lock-up-wrapper {
    padding: 15px 20px 40px
}

@media (min-width: 768px) {
    #footer-lock-up .lock-up-wrapper {
        padding: 35px 0 60px
    }
}

#footer-lock-up ul {
    width: 100%;
    margin: 0 auto;
    display: block;
    padding-left: 0
}

#footer-lock-up ul li {
    color: #a7a7a7;
    font: 400 12px/1.7em "Roboto", Helvetica, Arial, Sans-Serif
}

#footer-lock-up ul li a {
    color: #a7a7a7
}

#footer-lock-up ul .endorsement {
    clear: both;
    display: block
}

@media (min-width: 992px) {
    #footer-lock-up ul .endorsement {
        clear: none;
        float: left;
        display: inline-block
    }
}

#footer-lock-up ul .legal {
    clear: both;
    display: block;
    margin-top: 20px
}

@media (min-width: 992px) {
    #footer-lock-up ul .legal {
        clear: none;
        float: right;
        display: inline-block
    }
}

#footer-lock-up ul .pipe {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px
}

#newsletter-popup {
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99998;
    width: 460px
}

#newsletter-popup .btn {
    margin: 0;
    text-transform: capitalize;
    border: 1px solid transparent;
    vertical-align: top
}

#newsletter-popup .btn:focus {
    outline: none
}

#newsletter-popup .opt-in {
    color: #7F7F7F;
    font-size: 10px;
    margin-top: 5px
}

#newsletter-popup #newsletter-collapse {
    width: 100%;
    height: 100%;
    border-radius: 5px 5px 0 0;
    background-color: #323232;
    border-color: transparent;
    font-size: 14px;
    padding: 0px
}

#newsletter-popup #newsletter-collapse.newsletter-popup-not-collapsed .chevron:after {
    content: "\f078"
}

#newsletter-popup #newsletter-collapse .chevron:after {
    content: "\f077"
}

#newsletter-popup #newsletter-collapse-button {
    width: 40px;
    height: 34px;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
    background-color: #323232;
    margin: 0 0 0 auto
}

#newsletter-popup #newsletter-collapse-target {
    border-radius: 5px 0 0 0;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
    background-color: #323232;
    padding: 30px 30px 10px
}

#newsletter-popup .newsletter-popup-header {
    display: flex;
    align-items: center
}

#newsletter-popup .newsletter-popup-header span {
    font-family: "Roboto",Helvetica,Arial,Sans-Serif;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #FFF;
    padding: 0 0 0 15px
}

#newsletter-popup .newsletter-popup-body {
    font-family: "Lucida Grande",Arial,serif;
    font-size: 16px;
    line-height: 1.5;
    color: #FFF;
    margin: 18px 0 0
}

#newsletter-popup #newsletter-popup-email-input {
    min-width: 247px;
    height: 40px;
    background-color: #323232;
    font-family: "Roboto",Helvetica,Arial,Sans-Serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    color: #FFF;
    border: 1px solid #FFF;
    padding: 0 15px;
    box-sizing: border-box;
    flex-grow: 2;
    margin-right: 10px
}

#newsletter-popup #newsletter-popup-subscribe-btn {
    top: 0px;
    width: 143px;
    height: 40px;
    color: #FFF;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: bold;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: 20px 8px;
    background-image: url("../../assets/icons/mnav/newsletter.svg");
    background-color: #f07d31;
    padding: 6px 12px 6px 40px;
    line-height: 1
}

#newsletter-popup #newsletter-popup-form-wrapper {
    display: flex
}

#newsletter-popup #newsletter-popup-step-2 {
    display: none
}

#newsletter-popup #newsletter-popup-validation-message {
    display: none;
    color: #f07d31;
    transition: all 1s ease-in-out;
    font: 13px/15px "Roboto",Helvetica,Arial,Sans-Serif;
    margin-top: 10px
}

body.error404 footer.content-info,body.search footer.content-info,body.author footer.content-info,body.blog footer.content-info,body.single footer.content-info,body.home footer.content-info,body.page footer.content-info,body.archive footer.content-info,body.tag footer.content-info,body.category footer.content-info {
    background: #323232
}

body.error404 article footer,body.search article footer,body.author article footer,body.blog article footer,body.single article footer,body.home article footer,body.page article footer,body.archive article footer,body.tag article footer,body.category article footer {
    background: transparent
}

body.single footer.content-info #main-footer {
    margin-top: 40px
}

body.single footer.content-info #main-footer .main-footer-wrapper {
    border-top: none
}

body.home #main-nav .main-nav-home {
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    padding-left: 0
}

body.home #main-nav .main-nav-home .home-tip h1 {
    font: 15px/20px "Roboto";
    margin: 0px;
    padding: 0px
}

body.home.main-nav-mobile-fixed #main-nav .main-nav-home {
    padding-left: 40px
}

body.home.main-nav-mobile-fixed #main-nav .main-nav-mobile-search {
    display: none
}

@media (max-width: 1000px) {
    body.home #row-hero>div.fdrherotopcontainer-type {
        margin-top:-50px
    }

    body.home .hero-img {
        height: calc(100vw * (330/320) + 50px)
    }
}

.main-nav-minimal {
    display: none
}

@media (min-width: 769px) {
    body.minimal-nav .main-nav-nav,body.minimal-nav .main-nav-search {
        display:none
    }

    body.minimal-nav #main-nav.minimal-nav-open .main-nav-nav {
        display: block
    }

    body.minimal-nav #main-nav .main-nav-loggedin {
        margin-left: 0;
        padding-left: 20px;
        padding-right: 20px
    }

    body.minimal-nav .main-nav-minimal {
        display: flex;
        float: right
    }

    body.minimal-nav .main-nav-minimal>* {
        padding: 19px 20px
    }

    body.minimal-nav .main-nav-minimal>*:last-child {
        padding-right: 0
    }

    body.minimal-nav .main-nav-minimal [role=button] {
        color: #8e8e8e
    }

    body.minimal-nav .main-nav-minimal [role=button]:hover {
        color: #ff8a3a
    }

    body.minimal-nav .main-nav-minimal .fa,body.minimal-nav .main-nav-minimal .fas {
        font-size: 20px;
        line-height: 1
    }

    body.minimal-nav .main-nav-minimal.search>*:not(.main-nav-minimal-search) {
        display: none
    }

    body.minimal-nav .main-nav-minimal.search .main-nav-minimal-search {
        padding-right: 0
    }

    body.minimal-nav .main-nav-minimal.minimal-nav-expanded>*:not(.main-nav-minimal-toggle) {
        display: none
    }

    body.minimal-nav .main-nav-minimal.minimal-nav-expanded .main-nav-minimal-toggle {
        padding-right: 0
    }
}

#site-wide-alert {
    z-index: 1;
    transition: min-height .4s ease-in-out;
    width: 100%;
    margin-bottom: -1px
}

#site-wide-alert:not(:empty) {
    position: inherit
}

#site-wide-alert:empty {
    min-height: 42px;
    background-color: #dfdfdf
}

@media (min-width: 768px) {
    #site-wide-alert {
        margin-bottom:0px
    }
}

#site-wide-alert .announcement-wrap {
    display: block;
    text-decoration: none;
    color: #FFFFFF
}

#site-wide-alert .announcement-wrap #announcement-header {
    text-align: left;
    height: auto;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 12px 60px 12px 25px
}

@media (min-width: 768px) {
    #site-wide-alert .announcement-wrap #announcement-header {
        padding:10px 65px
    }
}

@media (min-width: 992px) {
    #site-wide-alert .announcement-wrap #announcement-header {
        padding:14px 45px
    }
}

#site-wide-alert .announcement-wrap #announcement-header .text-content {
    margin: 0 auto;
    width: fit-content;
    text-align: left;
    max-width: 100%
}

@media (min-width: 992px) {
    #site-wide-alert .announcement-wrap #announcement-header .text-content {
        max-width:60%
    }
}

#site-wide-alert .announcement-wrap #announcement-header .text-content .name {
    font-weight: 700;
    display: inline;
    vertical-align: top;
    text-transform: uppercase
}

#site-wide-alert .announcement-wrap #announcement-header .text-content .description {
    text-align: left;
    display: inline;
    margin-right: 5px
}

#site-wide-alert .announcement-wrap #announcement-header .text-content .read-more {
    display: inline;
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    white-space: nowrap
}

#site-wide-alert .announcement-wrap #announcement-header .text-content .read-more span {
    font-size: 20px;
    vertical-align: -3px
}

#site-wide-alert .announcement-wrap #announcement-header .close {
    position: absolute;
    right: 10px;
    padding-left: 10px;
    border-left: 1px solid rgba(255,255,255,0.2);
    opacity: 1;
    font-size: small;
    line-height: 0;
    top: 15px
}

@media (min-width: 768px) {
    #site-wide-alert .announcement-wrap #announcement-header .close {
        top:13px
    }
}

@media (min-width: 992px) {
    #site-wide-alert .announcement-wrap #announcement-header .close {
        top:18px
    }
}

@media (min-width: 1000px) {
    #nav-secondary #nav .nav-tab.nav-tab-more {
        display:none
    }
}

@media (max-width: 1000px) {
    #nav-secondary #nav .nav-tab.nav-tab-more {
        display:inline-block
    }
}

@media (min-width:1000px) {
    #nav-secondary #nav .nav-tab.nav-dropdown {
        display:inline-block
    }
}

@media (max-width: 1000px) {
    #nav-secondary #nav .nav-tab.nav-dropdown {
        display:none
    }
}

#nav-secondary #nav .nav-tab.nav-dropdown a .nav-more-button {
    display: inline-block;
    vertical-align: middle
}

#nav-secondary #nav .nav-tab.nav-dropdown a i {
    margin-left: 8px;
    color: #338280;
    vertical-align: middle;
    font-size: 17px
}

.more-menu-dropdown-container {
    display: none;
    position: absolute;
    z-index: 1000;
    background-color: #FFFFFF;
    border: 1px solid;
    border-color: #D7D7D7;
    width: 150px;
    right: 20px;
    text-align: left;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.1);
    top: 40px
}

.dropdown-more-list {
    padding: 4px 0 0 15px !important;
    text-transform: none !important
}

.dropdown-more-list:hover {
    color: #ffffff !important;
    background-color: #338280 !important
}
