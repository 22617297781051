.slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: none
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-track,.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slick-track:before,.slick-track:after {
    display: table;
    content: ''
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
}

[dir='rtl'] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.slick-prev,.slick-next {
    position: absolute;
    display: block;
    height: 56px;
    width: 58px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: rgba(74,74,74,0.5);
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 1
}

.slick-prev:hover,.slick-prev:focus,.slick-next:hover,.slick-next:focus {
    outline: none;
    background: rgba(74,74,74,0.5);
    background: rgba(74,74,74,0.5)
}

.slick-prev:hover:before,.slick-prev:focus:before,.slick-next:hover:before,.slick-next:focus:before {
    opacity: 1
}

.slick-prev:focus,.slick-next:focus {
    outline: 5px auto #e6c870
}

.slick-prev.slick-disabled:before,.slick-next.slick-disabled:before {
    opacity: 0.25
}

.slick-prev:before,.slick-next:before {
    font-family: "aurberge-icons";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75
}

.slick-prev {
    left: 0
}

[dir="rtl"] .slick-prev {
    left: auto;
    right: 0
}

.slick-prev:before {
    content: ""
}

[dir="rtl"] .slick-prev:before {
    content: ""
}

.slick-next {
    right: 0
}

[dir="rtl"] .slick-next {
    left: 0;
    right: auto
}

.slick-next:before {
    content: ""
}

[dir="rtl"] .slick-next:before {
    content: ""
}

.slick-dots {
    position: absolute;
    bottom: 34px;
    background-color: transparent;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.slick-dots li {
    position: relative;
    display: inline-block;
    height: 24px;
    width: 24px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    line-height: 1
}

.slick-dots li button {
    display: block;
    color: transparent;
    cursor: pointer;
    padding: 6px;
    margin: 0;
    background-color: transparent;
    line-height: 0px;
    font-size: 0px;
    border: 0
}

.slick-dots li button:before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #676767
}

.slick-dots li button:hover,.slick-dots li button:active {
    opacity: 1
}

.slick-dots li.slick-active button {
    padding: 5px
}

.slick-dots li.slick-active button:before {
    background-color: #e6c870;
    border: 2px solid #ffffff;
    -webkit-box-shadow: 0 0 0 1px #666666;
    box-shadow: 0 0 0 1px #666666;
    height: 15px;
    width: 15px;
    border-radius: 8px
}


.stories-module-slide {
    background-color: #f3f1ef
}

.stories-module-slide__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.stories-module-slide__block {
    padding: 20px 25px 0;
    max-width: 560px
}

.stories-module-slide__block--media {
    padding: 0;
    max-width: unset
}

.stories-module-slide__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 60px
}

.stories-module-slide__module-title {
    font-family: "Financier Light", Georgia, Times, serif;
    font-style: normal;
    font-size: 1.125rem;
    line-height: 1.22;
    padding-bottom: 40px;
    color: #383838;
    height: 65px;
}

.stories-module-slide__headline {
    line-height: 1.1
}

.stories-module-slide__sub-title {
    font-size: 0.75rem;
    font-family: "CentraNo1 Book", Helvetica, Verdana, sans-serif;
    text-transform: unset;
    letter-spacing: normal;
    color: #828180
}

.stories-module-slide__title {
    font-size: 1.75rem;
    line-height: 1.1;
    color: #383838
}

.stories-module-slide__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-size: 0.875rem;
    color: #383838;
    font-family: "CentraNo1 Book", Helvetica, Verdana, sans-serif
}

.stories-module-slide__link .icon {
    margin-left: 10px
}

.stories-module-slide__link:before {
    content: '';
    height: 1px;
    width: 100%;
    max-width: 0px;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    background-color: #e6c870;
    position: absolute;
    bottom: -5px;
    left: 0
}

.stories-module-slide__link:hover:before,.stories-module-slide__link:focus:before,.stories-module-slide__link:active:before {
    max-width: 200px
}

@media only screen and (min-width: 700px) {
    .stories-module-slide__module-title {
        font-size:1.5rem
    }

    .stories-module-slide__sub-title {
        font-size: 0.875rem;
        margin: 0
    }

    .stories-module-slide__title {
        font-size: 2.875rem
    }

    .stories-module-slide__block {
        padding: 35px 35px 0
    }

    .stories-module-slide__block--media {
        padding: 0
    }
}

@media only screen and (min-width: 1200px) {
    .stories-module-slide__container {
        -webkit-box-align:end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        position: relative
    }

    .stories-module-slide__module-title {
        position: absolute;
        top: 40px;
        bottom: 0
    }

    .stories-module-slide__block {
        width: 40%;
        padding: 0 60px 0 0
    }

    .stories-module-slide__block--media {
        width: 60%;
        height: auto
    }
}

.stories-module {
    padding: 20px
}

.stories-module .asset-holder .slick-dots {
    width: auto;
    bottom: 30px;
    right: 20px
}

.stories-module .asset-holder .slick-dots li {
    height: auto
}

.stories-module .asset-holder .slick-dots li:not(.slick-active) button:before {
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease
}

.stories-module .asset-holder .slick-dots li:not(.slick-active) button:hover:before,.stories-module .asset-holder .slick-dots li:not(.slick-active) button:focus:before,.stories-module .asset-holder .slick-dots li:not(.slick-active) button:active:before {
    background-color: #676767;
    border: 2px solid #ffffff;
    -webkit-box-shadow: 0 0 0 1px #666666;
    box-shadow: 0 0 0 1px #666666;
    height: 15px;
    width: 15px;
    border-radius: 8px
}

.stories-module .slick-track {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important
}

.stories-module .slick-slide {
    height: inherit !important
}

@media only screen and (min-width: 700px) {
    .stories-module {
        padding:40px
    }

    .stories-module .asset-holder .slick-dots {
        right: 34px
    }
}

@media only screen and (min-width: 1200px) {
    .stories-module .asset-holder .slick-dots {
        right:30px
    }
}

